import React, { useEffect, useCallback, useState } from 'react';
import classNames from 'classnames';
import Anime from 'animejs';
import ReactModal from 'react-modal';
import { useSetState } from 'ahooks';
import { throttle } from '@/utils/event-control';
import Input from '@/components/input';
import TextArea from '@/components/textarea';
import Button from '@/components/button';
import { isEmail, isMobilePhone } from '@/constants/regexp';
import ErrorContent from '@/business-components/user-login/error-content';
import { postFeedBack } from '@/services/feedback';
import styles from './index.module.scss';
export default function RightSideTools({}) {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [feedbackVisible, setFeedbackVisible] = useState(false);
  const [feedBack, setFeedback] = useSetState({ suggestion: '', contact: '' });
  const [error, setError] = useState('');
  const Tools = [
    {
      render: () => (
        <a href="/commonproblem" target="_blank" className={styles.commonproblem}>
          <svg className="icon" aria-hidden="true">
            <use xlinkHref="#icon-help_outline"></use>
          </svg>
        </a>
      ),
      // onClick: () => Router.push('/commonproblem'),
      hoverContent: <>常见问题</>,
    },
    {
      render: () => (
        <>
          <svg className="icon" aria-hidden="true">
            <use xlinkHref="#icon-sms_failed"></use>
          </svg>
        </>
      ),
      onClick: () => setFeedbackVisible(true),
      hoverContent: <>意见反馈</>,
    },
    {
      render: () => (
        <>
          <svg className="icon" aria-hidden="true">
            <use xlinkHref="#icon-headset_mic"></use>
          </svg>
        </>
      ),
      hoverContent: (
        <>
          联系客服
          <img src="//assets-oss.cbndata.com/FixrQwnWv_kpB4Bgx_NWopZovCLf" className={styles.serviceImg} />
        </>
      ),
    },
    {
      render: () => (
        <>
          <svg className="icon" aria-hidden="true">
            <use xlinkHref="#icon-publish"></use>
          </svg>
        </>
      ),
      onClick: () =>
        Anime({
          targets: document.getElementsByTagName('html')[0],
          scrollTop: '0', // -> from '28px' to '100%',
          easing: 'easeInOutQuad',
          duration: 300,
        }),
      hoverContent: '回到顶部',
      type: 'toTop',
      visible: false,
    },
  ];
  const [ToolsList, setToolsList] = useState<any>(Tools);
  const judgeScroll = useCallback(() => {
    if ((window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop) > 500) {
      setToolsList(ToolsList.map(item => (item.type === 'toTop' ? { ...item, visible: true } : item)));
    } else {
      setToolsList(ToolsList.map(item => (item.type === 'toTop' ? { ...item, visible: false } : item)));
    }
  }, [ToolsList]);
  useEffect(() => {
    document.addEventListener(
      'scroll',
      throttle(() => {
        judgeScroll();
      }, 500)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setSubmitSuccess(false);
    setError('');
  }, [feedbackVisible]);
  const handleFeedBack = useCallback(async () => {
    if (!feedBack.suggestion || !feedBack.contact || !!error) return;
    try {
      await postFeedBack({ global_feedback: { content: feedBack.suggestion, email: feedBack.contact } });
      setSubmitSuccess(true);
    } catch (error) {}
  }, [error, feedBack]);
  const handleClose = useCallback(() => {
    setFeedbackVisible(false);
    setFeedback({ suggestion: '', contact: '' });
  }, [setFeedback]);
  return (
    <div className={styles.rightSideTools}>
      <ReactModal
        isOpen={feedbackVisible}
        preventScroll
        onRequestClose={handleClose}
        overlayClassName={styles.ReactModal__Overlay}
        className={classNames(styles.ReactModal__Content)}
      >
        <div className={styles.feedback}>
          <svg className={classNames('icon', styles.closeIcon)} aria-hidden="true" onClick={handleClose}>
            <use xlinkHref="#icon-tag_unsubscribe" />
          </svg>
          {submitSuccess ? (
            <div className={styles.success}>
              <svg className={classNames('icon', styles.correctIcon)} aria-hidden="true">
                <use xlinkHref="#icon-right-1" />
              </svg>
              <div className={styles.title}>成功提交问题！</div>
              <div className={styles.content}>非常感谢您的反馈！</div>
              <div className={styles.content}>我们会认真考虑您的建议并对产品和服务进行优化</div>
            </div>
          ) : (
            <div className={styles.fillIn}>
              <div className={styles.title}>意见反馈</div>
              <div className={styles.suggestion}>
                <TextArea
                  placeholder="您的建议或遇到的问题..."
                  onChange={value => {
                    setFeedback({ suggestion: value });
                  }}
                />
              </div>
              <Input
                className={styles.input}
                onChange={value => {
                  setFeedback({
                    contact: value,
                  });
                  if (!isEmail.test(value) && !isMobilePhone.test(value)) {
                    setError('请输入正确的邮箱或手机号');
                  } else {
                    setError('');
                  }
                }}
                placeholder="请留下您的邮箱或手机号，方便我们及时沟通反馈（必填）"
              />
              <ErrorContent errText={error} style={{ marginTop: '8px' }} />
              <Button
                width={248}
                height={42}
                style={{ margin: '36px 0 44px' }}
                onClick={handleFeedBack}
                type={!feedBack.suggestion || !feedBack.contact || !!error ? 'default' : 'normal'}
              >
                提交
              </Button>
            </div>
          )}
        </div>
      </ReactModal>
      {ToolsList?.map((item, index) => (
        <div key={index}>
          <div
            className={classNames('flex-row-wrap', 'flex-center-center', styles.rightSideToolsItem)}
            key={index}
            onClick={item.onClick}
            style={item.type === 'toTop' && !item.visible ? { visibility: 'hidden' } : { visibility: 'visible' }}
          >
            {item.render()}
            <div className={styles.noticeTips}>{item.hoverContent}</div>
          </div>
        </div>
      ))}
    </div>
  );
}
