import React from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import styles from './index.module.scss';

interface LogoProps {
  className?: string;
}

export default function Logo({ className }: LogoProps) {
  return (
    <div className={classNames(styles.logo, className)}>
      <Link href={'/'}>
        <img
          className={styles.logoImage}
          src="//assets-oss.cbndata.com/cbndata-refactor-fe/FvLd08nxNlLXw7TRuskoy8oMo5Dt.png"
        />
      </Link>
    </div>
  );
}
